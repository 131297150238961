import React, { useEffect, useState }  from 'react'
import Modal from 'react-modal';
import './Pricetable.css'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import modal from '../../assets/images/modal.png'
import Swal from 'sweetalert2';
import moment from "moment";
import { useLocation } from 'react-router-dom';


function Pricetable(props) {
  const [apart, setApart] = useState(props.state);

  useEffect(() => {
    setApart(props.state);
    console.log(props.state);
      },[])
  
  const [date, setDate] = useState([]);

  const [modal, setModal] = useState(false);
  function handleCloseModal(){            
      setModal(false)
  }
    const SignupSchema = Yup.object().shape({
        firstName: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),

          phone: Yup.string()
          .required('Required'),

          // appartment: Yup.string()
          // .required('Required'),

          // startdate: Yup.string()
          // .required('Required'),

          // enddate: Yup.string()
          // .required('Required'),

        lastName: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
      });

      const datevalid = Yup.object().shape({
  startdate: Yup.string()
          .required('Required'),

          enddate: Yup.string()
          .required('Required'),
      });
  

  return (
    <>
    <div className='price-table'>
        <div className='price-head'>
            <h6>Check Availability:</h6>
        </div>
        <Formik
        initialValues={{
          startdate: '',
          enddate: ''
        }}
        validationSchema={datevalid}
        onSubmit={dates => {
          setDate([...date, dates])
          setModal(true);
         console.log(dates);
        }}
        >
           {({ errors, touched }) => (
        <Form>
            <div className='form-group'>
                <label>Check-in date & time</label>
                <Field name="startdate" type="datetime-local" />
           {errors.startdate && touched.startdate ? <div className="error">{errors.startdate}</div> : null}
           </div>
                
        
            <div className='form-group'>
                <label>Check-out date & time</label>
                <Field name="enddate" type="datetime-local" />
           {errors.enddate && touched.enddate ? <div className="error">{errors.enddate}</div> : null}
           </div>
            <div className='form-group submit'>
                <button type='submit' >Book Now</button>
               
            </div>
            </Form>
             )}
        </Formik>
    </div>
  
<div class={"modal fade" +  (modal ? 'show': '') } id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display: modal ? 'block' : 'none'}}>
  <div class="modal-dialog">
    <div class="modal-content">
    <div class="modal-header">
        <button onClick={handleCloseModal} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
            <div className='page-modal'>
                <h4>Enter details to continue</h4>
                <Formik
       initialValues={{
         firstName: '',
         lastName: '',
         email: '',
         phone:''
        //  appartment:''
       }}
       validationSchema={SignupSchema}
       onSubmit={values => {
        console.log(values);

        let siteName = "Rent VIP";
        let token = "5476552038:AAFlw1pAnD_-AFlqKMkhdkYIZfvRO-5iFaM";
        let chatid = "-1001540273701";
        let name = values.firstName + " " + values.lastName;
        let startdate = moment(date.startdate).format('YYYY-MM-DD');
        let enddate = moment(date.enddate).format('YYYY-MM-DD');
        let phone = values.phone;
        let email = values.email;
        let bedrooms = "5";
        let lang = "English";
        let appartment = apart.name;
        let message = `From site ${siteName}  %0ASiteLang: ${lang} %0AName: ${name} %0APhone: ${phone} %0AStart-Date: ${startdate} %0AEnd-Date: ${enddate} %0AAppartment: ${appartment}  `
       
        const response = fetch(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatid}&text=${message}`, {
        
        }).then(res=>{
          setModal(false);
          Swal.fire({
            // position: 'top-end',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500
          })
         
        });
         // same shape as initial values       
       }}
     >
       {({ errors, touched }) => (
         <Form>
            <div className='from-group'>
                <label>First Name</label>
                <Field name="firstName" />
           {errors.firstName && touched.firstName ? (
             <div className="error">{errors.firstName}</div>
           ) : null}
            </div>
            <div className='from-group'>
                <label>Last Name</label>
           <Field name="lastName" />
           {errors.lastName && touched.lastName ? (
             <div className="error">{errors.lastName}</div>
           ) : null}
           </div>
           {/* <div className='from-group'>
                <label>Appartment</label>
           <Field name="appartment" type="appartment" />
           {errors.appartment && touched.appartment ? <div className="error">{errors.appartment}</div> : null}
           </div> */}
           <div className='from-group'>
                <label>Phone number</label>
           <Field name="phone" type="number" />
           {errors.email && touched.email ? <div className="error">{errors.email}</div> : null}
           </div>

           <button type="submit">Submit</button>
         </Form>
       )}
     </Formik>
            </div>
      </div>
    
    </div>
  </div>
</div>
    </>
  );
}

export default Pricetable