import React, { useEffect } from "react";
import { useLocation, redirect } from "react-router-dom";

export default function ScrollTop({children}) {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }, []);

  return <>{children}</>;
}
