import React, { useEffect, useState } from 'react'
import "./Home.css";
import { Link } from 'react-router-dom';
import banner from '../../assets/images/banner.png'
import Services from '../Services/Services'
import ichomebg from '../../assets/images/ichomebg.png'
import Commonservices from '../Commonservices/Commonservices'
import Form from '../Form/Form'
import Property from '../Propery/Property';
import Car from '../Car/Car';
import Availnow from '../Availnow/Availnow';
import PropertySale from '../PropertySale/PropertySale';
import CarSale from '../CarSale/CarSale';
import Tourplans from '../Tourplans/Tourplans';
import Blogs from '../Blogs/Blogs';
import Ourcustomer from '../Ourcustomer/Ourcustomer';
import Ourapp from '../Ourapp/Ourapp';
import Explore from '../Explore/Explore';
import Tours from '../Tours/Tours';
import PropertyMobile from '../PropertyMobile/PropertyMobile';
import ExploreMobile from '../ExploreMobile/ExploreMobile';
import MobileCar from '../MobileCar/MobileCar';
import MobileTour from '../MobileTour/MobileTour';
import MobileServices from '../MobileServices/MobileServices';

function Banner() {
    const [UseProperty, SetProperty] = useState("property");
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {
      function handleWindowResize() {
        setWindowSize(window.innerWidth);
      }
  
      window.addEventListener('resize', handleWindowResize);
  
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    // const [Active, SetActive] = useState(Active);
   
  return (
    <div className='page-full-data'>
    <div className='banner-page'>
        {/* <img src={banner} alt="banner" /> */}
        <div className='container'>
           <div className='banner-data wow fadeInUp' data-wow-delay="0.2s">
                <h1>Hospitality <br/>and aesthetics</h1>
                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
           </div>
           <div className='select-property wow fadeInUp' data-wow-delay="0.6s">
                <ul>
                    
                    <li className={UseProperty == "property" && "active" }><Link onClick={() => { SetProperty("property")}} to="/">Property</Link></li>
                    <li className={UseProperty == "car" && "active" }><Link onClick={() => { SetProperty("car")}} to="/">Car Rental</Link></li>
                    <li className={UseProperty == "tours" && "active" }><Link onClick={() => { SetProperty("tours")}} to="/">Tours</Link></li>
                </ul>
                { UseProperty == "property" &&<Property /> }
                { UseProperty == "car" && <Car /> }
                { UseProperty == "tours" &&<Tours /> }
           </div>
        </div>
    </div>
    <>

    
    { windowSize < 767 && <div className='top-property-mob'> <PropertyMobile heading={"Properties near you"}/> </div>}
    { windowSize > 767 && <div className='top-property'> <PropertySale heading={"Properties near you"}/> </div> }
   

   { windowSize < 767 &&<ExploreMobile /> }
   { windowSize > 767 &&<Explore />}


    { windowSize < 767 && <PropertyMobile heading={"Buildings & Community"}/>}
    { windowSize > 767 && <PropertySale  heading={"Buildings & Community"}/>}

    { windowSize < 767 && <MobileServices /> }
     { windowSize > 767 && <Services /> }


    { windowSize < 767 &&<MobileCar /> }
    { windowSize > 767 && <CarSale /> }

    { windowSize < 767 && <MobileTour />}
    { windowSize > 767 && <Tourplans /> }

   <Blogs />
   <Ourapp />
    </>
    </div>
  )
}

export default Banner