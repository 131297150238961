import React from 'react'
import Slider from "react-slick"
import serthree from '../../assets/images/serthree.png'
import sertwo from '../../assets/images/sertwo.png'
import serone from '../../assets/images/serone.png'
import lines from '../../assets/images/lines.png'
import { Link } from 'react-router-dom'


function MobileServices() {
    var mobser = {
        dots: false,
        arrow: false,
        slidesToShow: 1.07,
       
      };
      const servicesList = [
        {img:serthree, name:'Rental Property', data:'It is a long established fact a reader will be some a random distracted by the readablity content just to add another line to the additional of a page when looking.', link:'Explore More'},
        {img:serone, name:'Car Rental', data:'Distracted by the readable content of a page when looking at and some of the random text its layout.', link:'Explore More'},
        {img:sertwo, name:'Touring', data:'It is a long established fact a reader will be some a random distracted by the readablity content just to add another line to the additional of a page when looking.', link:'Explore More'}

    ]
  return (
    <div className='mob-tour'>
    <div className='container'>
     <h4>Our Services</h4>
        <Slider {...mobser}>
        {servicesList.map((inservices) => 
                    
                    <div className='inner-ser'>
                        <img src={inservices.img} alt="" />
                        <h4>{inservices.name}</h4>
                        <p>{inservices.data}</p>
                        <Link to="/">{inservices.link} <i class="fas fa-arrow-right"></i>  </Link>
                    </div>
                   
                    )}
        </Slider>
    </div>
    </div>
  )
}

export default MobileServices