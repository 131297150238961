import React from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import exone from '../../assets/images/exone.png'
import extwo from '../../assets/images/extwo.png'

function ExploreMobile() {
    var settings = {
        dots: false,
        arrow: false,
        slidesToShow: 1.07,
       
      };
  return (
    <div className='explore-data-mobile'>
    <div className='container'>
    <h4 className='common-heading'>Explore Dubai</h4>
    <Slider {...settings}>
    <div className='explore-inner wow fadeInLeft' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                                <div className='sale-btn'><Link to="productlisting" >Book Now</Link><img src={exone} alt="propertyone" /></div>
                                <h5>Marina</h5>
                                <p>Contrary to popular belief, of classical Latin liter from </p>
                            </div>
                           
                    </div>
                    <div className='explore-inner wow fadeInLeft' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                                <div className='sale-btn'><Link to="productlisting" >Book Now</Link><img src={exone} alt="propertyone" /></div>
                                <h5>Marina</h5>
                                <p>Contrary to popular belief, of classical Latin liter from </p>
                            </div>
                           
                    </div>
                    <div className='explore-inner wow fadeInLeft' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                                <div className='sale-btn'><button to="productlisting">Book Now</button><img src={exone} alt="propertyone" /></div>
                                <h5>Marina</h5>
                                <p>Contrary to popular belief, of classical Latin liter from </p>
                            </div>
                           
                    </div>
    </Slider>
    </div>
    </div>
  )
}

export default ExploreMobile