import React from 'react'
import tourone from '../../assets/images/tourone.png'
import Slider from "react-slick";
import { Link } from 'react-router-dom'
function MobileTour() {
    var mobtour = {
        dots: false,
        arrow: false,
        slidesToShow: 1.07,
       
      };
  return (
    <div className='mob-tour'>
        <div className='container'>
        <h4>Luxury Tours</h4>
        <Slider {...mobtour}>
        <div className='inner-propery'>
                            <div className='sale-btn'><Link to="/productlisting" >Book Now</Link> <img src={tourone} alt="carone" /></div>
                                <h5>Helicoptor Tours</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                            </div>
                            <div className='inner-propery'>
                            <div className='sale-btn'><Link to="/productlisting" >Book Now</Link> <img src={tourone} alt="carone" /></div>
                                <h5>Helicoptor Tours</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                            </div>
                            <div className='inner-propery'>
                            <div className='sale-btn'><button>Book Now</button> <img src={tourone} alt="carone" /></div>
                                <h5>Helicoptor Tours</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                            </div>
                            </Slider>
        </div>
    </div>
  )
}

export default MobileTour