import React from 'react'
import { Link } from 'react-router-dom';
import "./Header.css";
import { useLocation } from 'react-router-dom';


function Header() {


  var color = useLocation().pathname == '/' ? '#fff' : '#000';

  const style  = {
    anchor_text: {
      color : color

    }
  }

  return (
   <div className='headerpage'>
    <div className='container'>
       <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
    <a style={style.anchor_text} className="navbar-brand" href="/">RentVIP</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={style.anchor_text}>
         <li><Link style={style.anchor_text} to="/">Home</Link></li>
         <li><Link style={style.anchor_text} to="productlisting">Apartments</Link></li>
         <li><Link style={style.anchor_text} to="productlisting">Property Listing</Link></li>
         <li><Link style={style.anchor_text} to="/">Tours</Link></li>

      </ul>
  
    </div>
    <div className='log-btn'>
      <button>Sign in / Login</button>
    </div>
  </div>
</nav>
</div>
   </div>
  )
}

export default Header