import React from 'react'
import carone from '../../assets/images/carone.png'
import cartwo from '../../assets/images/cartwo.png'
import carthree from '../../assets/images/carthree.png'
import carfour from '../../assets/images/carfour.png'
import carfive from '../../assets/images/carfive.png'
import './CarSale.css'
import { Link } from 'react-router-dom'

function CarSale() {
  return (
        <div className='car-services property'>
            <div className='container'>
            <h4>Luxury Cars</h4>
                {/* <p className='tp-brd'>Contrary to popular belief, of classical Latin content of a page when<br/> looking at its literature from 45 BC, making.</p> */}
            <div className='row'>
            <div className='col-md-4 wow bounceIn' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                            <div className='sale-btn'><Link to="/productlisting" >Book Now</Link><img src={carone} alt="carone" /></div>
                                <h5>Lorem Ipsum</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                                <div className='car-details'>
                                    <div>
                                        <span>Mileage (upto)</span>
                                        <h6>18.97Kmpl</h6>
                                    </div>
                                    <div>
                                        <span>Transmission</span>
                                        <h6>Automatic</h6>
                                    </div>
                                    <div>
                                        <span>Engine</span>
                                        <h6>1199 cc</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='inner-propery'>
                            <div className='sale-btn'><Link to="productlisting">Book Now</Link> <img src={cartwo} alt="cartwo" /></div>
                                <h5>Lorem Ipsum</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                                <div className='car-details'>
                                    <div>
                                        <span>Mileage (upto)</span>
                                        <h6>18.97Kmpl</h6>
                                    </div>
                                    <div>
                                        <span>Transmission</span>
                                        <h6>Automatic</h6>
                                    </div>
                                    <div>
                                        <span>Engine</span>
                                        <h6>1199 cc</h6>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className='col-md-4 wow bounceIn' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                            <div className='sale-btn'><Link>Book Now</Link> <img src={carthree} alt="carone" /></div>
                                <h5>Lorem Ipsum</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                                <div className='car-details'>
                                    <div>
                                        <span>Mileage (upto)</span>
                                        <h6>18.97Kmpl</h6>
                                    </div>
                                    <div>
                                        <span>Transmission</span>
                                        <h6>Automatic</h6>
                                    </div>
                                    <div>
                                        <span>Engine</span>
                                        <h6>1199 cc</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='inner-propery'>
                            <div className='sale-btn'><Link>Book Now</Link><img src={carfour} alt="cartwo" /></div>
                                <h5>Lorem Ipsum</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                                <div className='car-details'>
                                    <div>
                                        <span>Mileage (upto)</span>
                                        <h6>18.97Kmpl</h6>
                                    </div>
                                    <div>
                                        <span>Transmission</span>
                                        <h6>Automatic</h6>
                                    </div>
                                    <div>
                                        <span>Engine</span>
                                        <h6>1199 cc</h6>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className='col-md-4 wow bounceIn' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                            <div className='sale-btn'><Link>Book Now</Link> <img src={carfive} alt="carone" /></div>
                                <h5>Lorem Ipsum</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                                <div className='car-details'>
                                    <div>
                                        <span>Mileage (upto)</span>
                                        <h6>18.97Kmpl</h6>
                                    </div>
                                    <div>
                                        <span>Transmission</span>
                                        <h6>Automatic</h6>
                                    </div>
                                    <div>
                                        <span>Engine</span>
                                        <h6>1199 cc</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='inner-propery'>
                            <div className='sale-btn'><Link>Book Now</Link> <img src={cartwo} alt="cartwo" /></div>
                                <h5>Lorem Ipsum</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                                <div className='car-details'>
                                    <div>
                                        <span>Mileage (upto)</span>
                                        <h6>18.97Kmpl</h6>
                                    </div>
                                    <div>
                                        <span>Transmission</span>
                                        <h6>Automatic</h6>
                                    </div>
                                    <div>
                                        <span>Engine</span>
                                        <h6>1199 cc</h6>
                                    </div>
                                </div>
                            </div>
                    </div>
                    
            </div>
            <div className='view-all-btn'>
                <Link className='common-btn'><span>View All</span></Link>
                </div>
            </div>
        </div>

  )
}


export default CarSale