import React, { useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import './Filtter.css';

function Filtter() {
    const [ value, setValue ] = useState(0); 

  return (
        <div className='filter-main'>
                <div className='filter-list'>
                    <h6>By Category</h6>
                    <ul>
                        <li>Studio Appartment</li>
                        <li>Pent House</li>
                        <li>3 BHK</li>
                        <li>Sea Facing</li>
                        <li>Superior Studio Apartment</li>
                    </ul>
                    <h6>By location radius</h6>
                    <RangeSlider
      value={value}
      onChange={changeEvent => setValue(changeEvent.target.value)} />

<h6>By rental price</h6>
                    <ul>
                        <li>AED 300 and below</li>
                        <li>AED 300 - AED 500</li>
                        <li>AED 500 - AED 800</li>
                        <li>AED 800 - AED 1000</li>
                        <li>AED 1000 - AED 5000</li>
                    </ul>
                    <button className='filter-btn'>Filter</button>
                </div>
        </div>
  )
}

export default Filtter