import React from 'react'
import carone from '../../assets/images/carone.png'
import Slider from "react-slick";
import { Link } from 'react-router-dom'


function MobileCar() {
    var mobcar = {
        dots: false,
        arrow: false,
        slidesToShow: 1.07,
       
      };
  return (
   <div className='mobile-car'>
    <div className='container'>
    <h4>Featured Properties</h4>
    <Slider {...mobcar}>
    <div className="inner-propery wow fadeInLeft" data-wow-delay="0.2s">
                            <div className='sale-btn'><Link to="/productlisting" >Book Now</Link><img src={carone} alt="carone" /></div>
                                <h5>Lorem Ipsum</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                                <div className='car-details'>
                                    <div>
                                        <span>Mileage (upto)</span>
                                        <h6>18.97Kmpl</h6>
                                    </div>
                                    <div>
                                        <span>Transmission</span>
                                        <h6>Automatic</h6>
                                    </div>
                                    <div>
                                        <span>Engine</span>
                                        <h6>1199 cc</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="inner-propery wow fadeInLeft" data-wow-delay="0.2s">
                            <div className='sale-btn'><Link to="/productlisting" >Book Now</Link><img src={carone} alt="carone" /></div>
                                <h5>Lorem Ipsum</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                                <div className='car-details'>
                                    <div>
                                        <span>Mileage (upto)</span>
                                        <h6>18.97Kmpl</h6>
                                    </div>
                                    <div>
                                        <span>Transmission</span>
                                        <h6>Automatic</h6>
                                    </div>
                                    <div>
                                        <span>Engine</span>
                                        <h6>1199 cc</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="inner-propery wow fadeInLeft" data-wow-delay="0.2s">
                            <div className='sale-btn'><button>Book Now</button><img src={carone} alt="carone" /></div>
                                <h5>Lorem Ipsum</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                                <div className='car-details'>
                                    <div>
                                        <span>Mileage (upto)</span>
                                        <h6>18.97Kmpl</h6>
                                    </div>
                                    <div>
                                        <span>Transmission</span>
                                        <h6>Automatic</h6>
                                    </div>
                                    <div>
                                        <span>Engine</span>
                                        <h6>1199 cc</h6>
                                    </div>
                                </div>
                            </div>
    </Slider>
    </div>
   </div>
  )
}

export default MobileCar