import React, { useState, useEffect, useRef } from 'react'
import "./Productdetail.css";
import Slider from "react-slick";
import ichomebg from "../../assets/images/ichomebg.png";
import ic_hotel1 from "../../assets/images/ic_hotel1.png";
import icrestaurant3 from "../../assets/images/icrestaurant3.png";
import icrestaurant1 from "../../assets/images/icrestaurant1.png";
import icrestaurant2 from "../../assets/images/icrestaurant2.png";
import fr1 from "../../assets/images/fr1.png";
import Pricetable from '../Pricetable/Pricetable';
import lc from '../../assets/images/lc.png';
import map from '../../assets/images/map.png';
import build from '../../assets/images/build.webp'
import buildt from '../../assets/images/buildt.webp'
import buildr from '../../assets/images/buildr.webp'
import { useLocation } from 'react-router-dom';

function Productdetail(props) {
  const location = useLocation();
  const state = location.state;
  console.log(state);
 
  const [nme, setName] = useState(state);


  useEffect(() => {
    // console.log(ref);
    // ref.current.scrollTo(0, 0);
  }, []);
    var settings = {
        dots: false,
        arrow: true,
        speed: 500,
        slidesToScroll: 2,
        slidesToShow: 4.06,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              dots: false,
        arrow: false,
        slidesToShow: 1.07,
            }
          }
          ]
      };
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const rentaldata = [
        {img:icrestaurant2, ints:'Interested', name:'Full Ocean View - 2 bed High floor JBR - Amwaj 4', text:'Bantam, 456 Contemporary American', btn:'Explore More', thumbs:'fa fa-thumbs-up',angle:'fa fa-angle-right' },
        {img:icrestaurant3, ints:'Interested', name:'Charleston Grills', text:'Bantam, 456 Contemporary American', btn:'Explore More', thumbs:'fa fa-thumbs-up',angle:'fa fa-angle-right' },
        {img:icrestaurant1, ints:'Interested', name:'Cafe’ Provence', text:'Bantam, 456 Contemporary American', btn:'Explore More', thumbs:'fa fa-thumbs-up',angle:'fa fa-angle-right' },
        {img:icrestaurant3, ints:'Interested', name:'Arethusa al Tavolo', text:'Bantam, 456 Contemporary American', btn:'Explore More', thumbs:'fa fa-thumbs-up',angle:'fa fa-angle-right' },
        {img:icrestaurant2, ints:'Interested', name:'Charleston Grills', text:'Bantam, 456 Contemporary American', btn:'Explore More', thumbs:'fa fa-thumbs-up',angle:'fa fa-angle-right' },
        {img:icrestaurant3, ints:'Interested', name:'Cafe’ Provence', text:'Bantam, 456 Contemporary American', btn:'Explore More', thumbs:'fa fa-thumbs-up',angle:'fa fa-angle-right' }


    ];

  return (
    <div className='product-detail-page'>
                 <div className='container'>
                 <div className='product-slider-main'>
           <div className='main-sider'>
            <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} dots={true}>
        <div>
          <img src={require('../../assets/images/'+nme.image[0].name+'.webp')}alt="" />
        </div>
        <div>
        <img src={buildt}alt="" />
        </div>
        <div>
        <img src={buildr}alt="" />
        </div>
       
      </Slider>
      </div>
      <div className='side-sider'>
      <Slider
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        slidesToShow={3}
        swipeToSlide={true}
        focusOnSelect={true}
      >
        <div>
        <img src={require('../../assets/images/'+nme.image[0].name+'.webp')}alt="" />
        </div>
        <div>
        <img src={buildt}alt="" />
        </div>
        <div>
        <img src={buildr}alt="" />
        </div>
      
      </Slider>
  
      <div className='more-hotos'>
            <p>+12 photos</p>
      
            
      </div>
      </div>
      </div>
        <div className='row'>
            <div className='col-md-9'>
             
      <div className='slider-desc'>
        <h4>{nme && nme.name} <label>{nme && nme.price}<span>/day</span></label></h4>
        <h5 className='amp-loc'><img src={lc} alt="" />84746 O'Connell Station, Abu Dhabi Emirate, Al Dhafrah Region</h5>
        {/* <p>{items.desc}</p> */}
        <ul className='amnites'>
            <li>8 Guests</li>
            <li>4 Bedroom</li>
            <li>3 Bathroom</li>
            <li>Private Pool</li>
        </ul>
        <ul className="amnites-faeture">
          <li><span>Minimalistic</span></li>
          <li><span>Beach House</span></li>
          <li><span>Tropic</span></li>
          <li><span>Private Pool</span></li>
        </ul>
        <h6>Description</h6>
        
        <p>{nme && nme.description}</p>
        <h6>Property Feature</h6>
        <ul className='list-gyr'>
            <li>- Stunning swimming pool and Gym access</li>
            <li>- Full Marina View</li>
            <li>- Allocated Parking</li>
            <li>- Balcony</li>
            <li>- Free high-speed WiFi</li>
            <li>- Kitchen with full appliances</li>
            <li>- 5 Star Hotel facilities</li>
        </ul>
        <h6>Ammenities</h6>
        <ul className='amnites aminity-wh-img'>
            <li><img src={fr1} alt="" />Furniture</li>
            <li><img src={fr1} alt="" />Basement</li>
            <li><img src={fr1} alt="" />Pent House</li>
            <li><img src={fr1} alt="" />Fire Alarm</li>
            <li><img src={fr1} alt="" />Elevator</li>
            <li><img src={fr1} alt="" />Theft Alarm</li>
            <li><img src={fr1} alt="" />Base. Parking</li>
            <li><img src={fr1} alt="" />Swimming Pool</li>
        </ul>
        <h6>Location</h6>
        <div className='map'>
          <img src={map} alt="" />
        </div>
        <span>Bantam, 456 Contemporary American</span>
        
      </div>
      </div>
      <div className='col-md-3'>
                <Pricetable state={nme} />
      </div>
            </div>
        </div>
        <div className='you-like-slider'>
          <div className='container'>
            <h3>You may also like</h3>
            <div className='inn-th-slide'>
            <Slider {...settings}>
                {rentaldata.map((rentaldatamap) =>
                 <div>
                 <div className='iner-rental'>
                        <div className='rent-img'>
                        <img src={rentaldatamap.img} alt="" />
                        {/* <span><i class={rentaldatamap.thumbs} aria-hidden="true"></i>{rentaldatamap.ints}</span> */}
                        </div>
                        <h4>{rentaldatamap.name}</h4>
                        <div className='inter-btn'>
                        <p>{rentaldatamap.text}</p>
                        {/* <button className='common-btn'><span>{rentaldatamap.btn} <i class={rentaldatamap.angle} aria-hidden="true"></i></span></button> */}
                        </div>
                    </div>
                 </div>

                )}
       
        </Slider>
            </div>
            </div>
        </div>
     

    </div>
  )
}

export default Productdetail