import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios";
import propertytwo from '../../../assets/images/propertytwo.png'
import propertyfour from '../../../assets/images/propertyfour.png'
import propertyfive from '../../../assets/images/propertyfive.png'
import build from '../../../assets/images/build.webp'
import buildt from '../../../assets/images/buildt.webp'
import buildr from '../../../assets/images/buildr.webp'
import filterimg from '../../../assets/images/filterimg.png'
import { useNavigate } from "react-router-dom";
import Filtter from '../../Filtter/Filtter'
import igone from '../../../assets/images/igone.webp'
import igtwo from '../../../assets/images/igtwo.webp'
import igthree from '../../../assets/images/igthree.webp'
import igfour from '../../../assets/images/igfour.webp'
import igfive from '../../../assets/images/igfive.webp'
import igsix from '../../../assets/images/igsix.webp'
import igseven from '../../../assets/images/igseven.webp'
import igeight from '../../../assets/images/igeight.webp'
import ignine from '../../../assets/images/ignine.webp'
import igten from '../../../assets/images/igten.webp'
import igeve from '../../../assets/images/igeve.webp'
import igtwe from '../../../assets/images/igtwe.webp'
import igthr from '../../../assets/images/igthr.jpeg'
import igfr from '../../../assets/images/igfr.webp'

import igkk from '../../../assets/images/igkk.jpg'
import igvv from '../../../assets/images/igvv.webp'
import igls from '../../../assets/images/igls.webp'
import igig from '../../../assets/images/igig.webp'

function ProductListing(props) {
    const [filter, setFilter] = useState(false);
    const openFilter = () => {
        setFilter(!filter);
    }
    const [userdata, setUser] = useState([]);

    const fetchData = () => {
        return axios.get("https://socket.rentvip.com/api/home")
              .then((response) => setUser(response.data.data.properties));
           
      }
      console.log(userdata);
    
      useEffect(() => {
        fetchData();
      },[])


    const ar1=[
        {
            _id: "63b3fa681aefc94e0b2d0592",
            name: "Address Dubai Marina Res. 1 bed Suite Marina view",
            latitude: "23.99",
            longitude: "46.67",
            location: "Dubai, United Arab Emirates",
            description: "The living area of the apartment has a split use for both relaxation and dining alike; featuring a 50” wall mounted TV, corner sofa, dining table that seats four comfortably. Headed with floor to ceiling access to the balcony with gorgeous views of Marina canal and city, the room is bathed in natural lighting leaving it bright and airy. The kitchen is fully equipped with all necessary appliances for your convenience. The apartment comes with one bathroom with a large window next to the bath with a stunning view of the city.",
            image: [
                {
                    name: build,
                },
                {
                    name: "name"
                }
            ],
            "createdAt": "2023-01-03T09:50:32.799Z",
            "updatedAt": "2023-01-03T09:50:32.799Z"
        }
        // {
        //     id:1,
        //     name:"Address Dubai Marina Res. 1 bed Suite Marina view",
        //     desc:"Bantam, 456 Contemporary South India",
        //     img:build,
        //     btn:"/productdetail"
        // },

        // {
        //     id:2,
        //     name:"Address Dubai Marina Res. 1 Bed full marina view",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:buildt,
        //     btn:"/productdetail"
        // },
        // {
        //     id:3,
        //     name:"Full Ocean View - 2 bed High floor JBR - Amwaj 4",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:buildr,
        //     btn:"/productdetail"
        // },
        // {
        //     id:4,
        //     name:"Address Dubai Marina Res. 1 bed Suite 5* View",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igone,
        //     btn:"/productdetail"
        // },
        // {
        //     id:5,
        //     name:"VIP Castles EB Suites - 2 Bed w/ City Skyline View",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igtwo,
        //     btn:"/productdetail"
        // },
        // {
        //     id:6,
        //     name:"Hotel Apartment Address JBR - High-Floor 2 Bedroom",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igthree,
        //     btn:"/productdetail"
        // },
        // {
        //     id:7,
        //     name:"Full Burj & Fountain view , Lux 2 bed high floor",
        //     desc:"Bantam, 456 Contemporary South India",
        //     img:igfour,
        //     btn:"/productdetail"
        // },

        // {
        //     id:8,
        //     name:"Sea view Cayan Tower lux facilities on the walk",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igfive,
        //     btn:"/productdetail"
        // },
        // {
        //     id:9,
        //     name:"VIP Castles Suites - Upgraded 3+M in City Walk",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igsix,
        //     btn:"/"
        // },
        // {
        //     id:10,
        //     name:"VIP Castles Suites - Upgraded Duplex 4+M Penthouse",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igseven,
        //     btn:"/productdetail"
        // },
        // {
        //     id:11,
        //     name:"VIP Castles Suites - Minimalist 3BR in Address JBR",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igeight,
        //     btn:"/productdetail"
        // },
        // {
        //     id:12,
        //     name:"VIP Castles Suites - Luxury Duplex 2BR in Downtown",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:ignine,
        //     btn:"/"
        // },
        // {
        //     id:13,
        //     name:"VIP Castles BW Suite - 2 Bed w/ Ain Dubai View",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igten,
        //     btn:"/productdetail"
        // },
        // {
        //     id:14,
        //     name:"VIP Castles Suites - 2BR in Address JBR w/Sea View",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igeve,
        //     btn:"/"
        // },
        // {
        //     id:15,
        //     name:"Hotel Apartment Address JBR - High-Floor 2 Bedroom",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igtwe,
        //     btn:"/productdetail"
        // },
        // {
        //     id:16,
        //     name:"VIP Castles Bluewaters Suite - Full Ain Dubai view",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igthr,
        //     btn:"/"
        // },
        // {
        //     id:17,
        //     name:"VIP Castles Marina Suites - Elegant 3BR w/Sea View",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igfr,
        //     btn:"/productdetail"
        // },
        // {
        //     id:18,
        //     name:"Hotel Apartment Address JBR - High-Floor 2 Bedroom",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igtwe,
        //     btn:"/productdetail"
        // },
        // {
        //     id:19,
        //     name:"VIP Castles Suites - Luxury 3 Bedroom w/ Sea View",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igkk,
        //     btn:"/"
        // },
        // {
        //     id:20,
        //     name:"VIP Castles JBR Suites - Upgraded 3 Bed in Sadaf 4",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igvv,
        //     btn:"/productdetail"
        // },
        // {
        //     id:21,
        //     name:"VIP Castles Suites - Upgraded w/ Burj Khalifa View",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igls,
        //     btn:"/productdetail"
        // },
        // {
        //     id:22,
        //     name:"VIP Castles BW - Upgraded 2 Bed w/ Ain Dubai View",
        //     desc:"Contrary to popular belief, of classical Latin literature from 45 BC, making.",
        //     img:igig,
        //     btn:"/productdetail"
        // }
    ]
    console.log(ar1[0].image[0].name)
  return (
    <div className='product-liating-page'>
        <div className='container'>
    <div className='pagination'>
        <ul>
            <li><a href='/'>Home</a></li>
            <li><a href='#' className='active'>Property Details</a></li>
        </ul>
    </div>
    <div className='list-filter'>
        <h3>Properties near you</h3>
        <div className={filter? 'filter active': filter}>

         <button onClick={openFilter}><span><img src={filterimg} alt="" /></span>Filter</button>
            {filter && <Filtter /> }
        </div>
    </div>
    <div className='pro-list'>
    <div className='row'>
                    
                    {userdata && userdata.map((property,i) => 
                            <div className='col-md-4 wow fadeInLeft' datawow-delay="0.2s" key={i}>
                            <div className='inner-propery'>
                                <div className='sale-btn'><Link to="/productdetail" state={property}>Book Now</Link><img src={require('../../../assets/images/'+property.image[0].name+'.webp')} alt="propertyone" /></div>
                                <h5>{property.name}</h5>
                                <p>{property.location}</p>
                            </div>
                           
                    </div>
                    )}
    </div>
    </div>
    </div>
    </div>
  )
}

export default ProductListing