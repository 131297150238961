import React from 'react'
import { Link } from 'react-router-dom';
import "./Footer.css";
import ftone from '../../assets/images/ftone.png';
import fttwo from '../../assets/images/fttwo.png';
import ftthree from '../../assets/images/ftthree.png';
import ftfour from '../../assets/images/ftfour.png';

function Footer() {
  return (
    <div className='footer'>
        <div className='container'>
            <div className='register-property'>
                <h2>Register your<br /> properties with us.</h2>
                <p>With best-in-class properties and services that maximizes value we are putting  best-in-class properties.</p>
                <Link to="/">Register property with us <i class="fas fa-arrow-right"></i></Link>
                <img className='ft-img-one' src={ftone} alt="" />
                <img className='ft-img-two' src={fttwo} alt="" />
                <img className='ft-img-three' src={ftthree} alt="" />
                <img className='ft-img-four' src={ftfour} alt="" />
            </div>
            <div className='fotter-menu'>
                <div className='row'>
                    <div className='col-md-3'>
                    <div className='footer-left'>
                    <a class="navbar-brand" href="#">RentVIP</a>
                        <p>With best-in-class properties and services that maximizes value we are putting  best-in-class properties and services that With best-in-class properties and services. </p>
                        {/* <ul>
                            <li><i class="fa fa-phone" aria-hidden="true"></i>+971 44767898</li>
                            <li><i class="fa fa-envelope" aria-hidden="true"></i>www.loreum.com</li>
                        </ul> */}
                    </div>

                    </div>
                    <div className='col-md-2'>
                    <div className='footer-menu'>
                         <h5>Services</h5>               
                         <ul>
                            <li><a href="">Rental Property</a></li>
                            <li><a href="">Rental Cars</a></li>
                            <li><a href="">Tours</a></li>
                            
                        </ul>
                    </div>

                    </div>
                    <div className='col-md-2'>
                    <div className='footer-menu'>
                         <h5>Company</h5>               
                         <ul>
                            <li><a href="">Lorem</a></li>
                            <li><a href="">Facalty</a></li>
                            <li><a href="">Options</a></li>
                            
                        </ul>
                    </div>
                    </div>
                    <div className='col-md-2'>
                    <div className='footer-menu'>
                         <h5>Contact Us</h5>               
                         <ul>
                            <li><a href="">+644 6655 654</a></li>
                            <li><a href="">example@gmail.com</a></li>
                            
                        </ul>

                    </div>

                    </div>
                    <div className='col-md-3'>
                    <div className='footer-menu social'>
                                   
                         <ul>
                            <li><a href="#"><i class="fab fa-facebook-square"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                            <li><a href="#"><i class="fab fa-google"></i></a></li>
                            {/* <li><a href="#"><i class="fab fa-twitter-square"></i></a></li>
                            <li><a href="#"><i class="fab fa-whatsapp-square"></i></a></li> */}
                            
                        </ul>

                    </div>

                    </div>
                    </div>
                    </div>
                    <div className='lower-fr'>
                        <p>© Rentvip 2022</p>
                        <ul>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Site Credits</a></li>
                        </ul>
                    </div>
                </div>
        </div>

  )
}

export default Footer