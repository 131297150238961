import React, { useEffect, useState } from 'react'
import axios from "axios";
import "./PropertySale.css"
import { Link } from 'react-router-dom'
import propertyone from '../../assets/images/propertyone.png'
import propertytwo from '../../assets/images/propertytwo.png'
// import propertythree from '../../assets/images/propertythree.png'
import propertyfour from '../../assets/images/propertyfour.png'
import propertyfive from '../../assets/images/propertyfive.png'
import build from '../../assets/images/build.webp'
import buildt from '../../assets/images/buildt.webp'
import buildr from '../../assets/images/buildr.webp'
import igone from '../../assets/images/igone.webp'
import igtwo from '../../assets/images/igtwo.webp'
import igthree from '../../assets/images/igthree.webp'
import { useNavigate } from "react-router-dom";

function Property(props, heading, headingnew) {


    const [userdata, setUser] = useState([]);

    const fetchData = () => {
        return axios.get("https://socket.rentvip.com/api/home")
              .then((response) => setUser(response.data.data.properties.splice(0,6)));
           
      }
      console.log(userdata);
    
      useEffect(() => {
        fetchData();    
      },[])
  return (
    <div className='property'>
            <div className='container'>
                <h4>{props.heading}</h4>
                {/* <p className='tp-brd'>Contrary to popular belief, of classical Latin content of a page when<br/> looking at its literature from 45 BC, making.</p> */}
                <div className='row'>
                    
                    {userdata && userdata.map((property,i) => 
                          <div className='col-md-4' datawow-delay="0.2s" key={i}>
                          <div className='inner-propery'>
                              <div className='sale-btn'><Link to="/productdetail" state={property}>Book Now</Link><img src={require('../../assets/images/'+property.image[0].name+'.webp')} alt="propertyone" /></div>
                              <h5>{property.name}</h5>
                              <p>{property.location}</p>
                          </div>
                         
                  </div>
                    )}
                </div>
                <div className='view-all-btn'>
                <Link to="productlisting" className='common-btn'><span>View All</span></Link>
                </div>
            </div>
    </div>
  )
}

export default Property
