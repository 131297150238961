import React from 'react'
import './Blogs.css'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import blogone from '../../assets/images/blogone.png'
import blogtwo from '../../assets/images/blogtwo.png'
import blogthree from '../../assets/images/blogthree.png'

function Blogs() {
    var blogsslider = {
        dots: false,
        arrow: true,
        loop: true,
        slidesToScroll: 3,
        slidesToShow: 3,
        speed: 500,
        infinite: true,
        autoplaySpeed:1500,
        cssEase: 'linear',
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1.07,
                
              }
            },
        ]
      };
  return (
    <div className='blogs property'>
        <div className='container'>
            <h4>Our Blogs</h4>
            {/* <p className='tp-brd'>Contrary to popular belief, of classical Latin content of a page when<br/> looking at its literature from 45 BC, making.</p> */}
            <div className='blog-slider'>
            <Slider {...blogsslider}>
                
                 <div>
                  <div className='inner-propery'>
                                <img src={blogone} alt="carone" />
                                <h5>Distracted by the readable</h5>
                                <p>It is a long established fact that a reader will be distracted by the readable distracted by the readable content of a page when looking at its layout... <Link to ="/">Read More</Link></p>
                            </div>
                 </div>
                 <div>
                  <div className='inner-propery'>
                                <img src={blogtwo} alt="carone" />
                                <h5>Distracted by the readable</h5>
                                <p>It is a long established fact that a reader will be distracted by the readable distracted by the readable content of a page when looking at its layout... <Link to ="/">Read More</Link></p>

                            </div>
                 </div>
                 <div>
                  <div className='inner-propery'>
                                <img src={blogthree} alt="carone" />
                                <h5>Distracted by the readable</h5>
                                <p>It is a long established fact that a reader will be distracted by the readable distracted by the readable content of a page when looking at its layout... <Link to ="/">Read More</Link></p>

                            </div>
                 </div>
                 <div>
                  <div className='inner-propery'>
                                <img src={blogone} alt="carone" />
                                <h5>Distracted by the readable</h5>
                                <p>It is a long established fact that a reader will be distracted by the readable distracted by the readable content of a page when looking at its layout... <Link to ="/">Read More</Link></p>

                            </div>
                 </div>
        </Slider>
            </div>
        </div>
    </div>
  )
}

export default Blogs