import React from 'react'
import './Tourplans.css'
import { Link } from 'react-router-dom'
import tourone from '../../assets/images/tourone.png'
// import tourtwo from '../../assets/images/tourtwo.png'
import tourthree from '../../assets/images/tourthree.png'

function Tourplans() {
  return (
    <div className='tour-services property'>
    <div className='container'>
    <h4>Luxury Tours</h4>
        {/* <p className='tp-brd'>Contrary to popular belief, of classical Latin content of a page when<br/> looking at its literature from 45 BC, making.</p> */}
        <div className='row'>
            <div className='col-md-4 wow bounceIn' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                            <div className='sale-btn'><Link to="/productlisting" >Book Now</Link> <img src={tourone} alt="carone" /></div>
                                <h5>Helicoptor Tours</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                            </div>
                    </div>
                    <div className='col-md-4 wow bounceIn' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                            <div className='sale-btn'><button>Book Now</button> <img src={tourone} alt="carone" /></div>
                                <h5>Helicoptor Tours</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                            </div>
                    </div>
                    <div className='col-md-4 wow bounceIn' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                            <div className='sale-btn'><button>Book Now</button> <img src={tourthree} alt="carone" /></div>
                                <h5>Helicoptor Tours</h5>
                                <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                            </div>
                    </div>
                    </div>
                    <div className='view-all-btn'>
                <button className='common-btn'><span>View All</span></button>
                </div>
    </div>
    </div>
  )
}

export default Tourplans