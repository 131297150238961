import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Rentalcar from "./components/Rentalcar/Rentalcar";
import Rentalplace from "./components/Rentalplace/Rentalplace";
import Home from "./components/Home/Home";
import Commonservices from "./components/Commonservices/Commonservices";
import Productdetail from "./components/Productdetail/Productdetail";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ProductListing from "./components/InnerPage/ProductListing/ProductListing";
import ScrollTop from "./components/ScrollTop";

function Main() {

  return (
    <ScrollTop>
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="rentalplace" element={<Rentalplace />} />
        <Route exact path="rentalcar" element={<Rentalcar />} />
        <Route exact path="productdetail/" element={<Productdetail />} />
        <Route exact path="productlisting" element={<ProductListing />} />
      </Routes>
      <Footer />
    </Router>
    </ScrollTop>
  );
}

export default Main;
