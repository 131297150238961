import React, { useState, useEffect } from "react";

import "./App.css";

import Stylesheet from "../src/assets/fonts/Stylesheet.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Main from "./Main";
import Footer from "./components/Footer/Footer";
import y from "wowjs";
import Productdetail from "./components/Productdetail/Productdetail";
import "../src/assets/responsive.css";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';


function App() {
  // useEffect(() => {
  //   window.wow = new WOW.WOW({
  //     live: false
  //   });

  //   window.wow.init();
    
  // }, []);

  return (
    <div className="main-page">
      <Main />
    </div>
  );
}

export default App;
