import React from 'react'
import "./Rentalcar.css";
import icrestaurant1 from '../../assets/images/icrestaurant1.png';
import icrestaurant2 from '../../assets/images/icrestaurant2.png';
import icrestaurant3 from '../../assets/images/icrestaurant3.png';

function Rentalcar() {
    const rentaldata = [
        {img:icrestaurant2, ints:'Interested', name:'Arethusa al Tavolo', text:'Bantam, 456 Contemporary American', btn:'Explore More', thumbs:'fa fa-thumbs-up',angle:'fa fa-angle-right' },
        {img:icrestaurant3, ints:'Interested', name:'Charleston Grills', text:'Bantam, 456 Contemporary American', btn:'Explore More', thumbs:'fa fa-thumbs-up',angle:'fa fa-angle-right' },
        {img:icrestaurant1, ints:'Interested', name:'Cafe’ Provence', text:'Bantam, 456 Contemporary American', btn:'Explore More', thumbs:'fa fa-thumbs-up',angle:'fa fa-angle-right' },
        {img:icrestaurant3, ints:'Interested', name:'Arethusa al Tavolo', text:'Bantam, 456 Contemporary American', btn:'Explore More', thumbs:'fa fa-thumbs-up',angle:'fa fa-angle-right' },
        {img:icrestaurant2, ints:'Interested', name:'Charleston Grills', text:'Bantam, 456 Contemporary American', btn:'Explore More', thumbs:'fa fa-thumbs-up',angle:'fa fa-angle-right' },
        {img:icrestaurant3, ints:'Interested', name:'Cafe’ Provence', text:'Bantam, 456 Contemporary American', btn:'Explore More', thumbs:'fa fa-thumbs-up',angle:'fa fa-angle-right' }


    ];
  return (
    <div className='rental-cat'>
    <div className='container'>
        <div className='row'>
            {rentaldata.map((rentaldatamap) =>
             <div className='col-md-4'>
             <div className='iner-rental'>
                 <div className='rent-img'>
                 <img src={rentaldatamap.img} alt="" />
                 <span><i class={rentaldatamap.thumbs} aria-hidden="true"></i>{rentaldatamap.ints}</span>
                 </div>
                 <h4>{rentaldatamap.name}</h4>
                 <div className='inter-btn'>
                 <p>{rentaldatamap.text}</p>
                 <button className='common-btn'><span>{rentaldatamap.btn} <i class={rentaldatamap.angle} aria-hidden="true"></i></span></button>
                 </div>
             </div>
         </div>
            )}
           
        </div>
             <div className='explore-more'>
            <button className='common-btn'><span>Explore More <i class="fa fa-angle-right" aria-hidden="true"></i></span></button>
            </div>
    </div>
</div>
  )
}

export default Rentalcar