import React from 'react'
import "./Form.css";
import { useForm } from "react-hook-form";

function Form() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
      } = useForm();
      const onSubmit = (data) => {
        console.log(data);
      }; 
    
      console.log(watch("example"));
  return (
   <div className='contact-from'>
    <div className='container'>
        <div className='head-line '>
            <h2>Let us know what you want!</h2>   
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Your Name</label>
                            <input type='text'  {...register("namerequired", { required: true })}/>
                            {errors.namerequired && <p>This field is required</p>}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Your Email</label>
                            <input type='text' {...register("emailrequired", { required: true })} />
                            {errors.emailrequired && <p>This field is required</p>}
                        </div>
                    </div>
                    <div className='col-md-12 mt-5'>
                        <div className='form-group'>
                            <label>Your Query</label>
                           <textarea {...register("textrequired", { required: true })}/>
                           {errors.textrequired && <p>This field is required</p>}
                        </div>
                    </div>
                    <div className='col-md-12 mt-3'>
                        <div className='form-group'>
                           <input type="submit" value="Submit query" />
                        </div>
                    </div>
                </div>
                
            </form>
    </div>
   </div>
  )
}

export default Form