import React from 'react'
import "./Explore.css";
import { Link } from 'react-router-dom'
import exone from '../../assets/images/exone.png'
import extwo from '../../assets/images/extwo.png'

function Explore() {
    
  return (
        <div className='explore-data'>
            <div className='container'>
            <h4 className='common-heading'>Explore Dubai</h4>
            <div className='expolre-list'>
            <div className='explore-inner wow fadeInLeft' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                                <div className='sale-btn'><a href="productlisting" >Book Now</a><img src={exone} alt="propertyone" /></div>
                                <h5>Marina</h5>
                                <p>Contrary to popular belief, of classical Latin liter from </p>
                            </div>
                           
                    </div>
                    <div className='explore-inner wow fadeInLeft' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                                <div className='sale-btn'><Link to="/">Book Now</Link><img src={extwo} alt="propertyone" /></div>
                                <h5>Downtown (Burj Khalifa)</h5>
                                <p>Contrary to popular belief, of classical Latin liter from </p>
                            </div>
                           
                    </div>
                    <div className='explore-inner wow fadeInLeft' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                                <div className='sale-btn'><Link to="/">Book Now</Link><img src={exone} alt="propertyone" /></div>
                                <h5>JBR</h5>
                                <p>Contrary to popular belief, of classical Latin liter from </p>
                            </div>
                           
                    </div>
                    <div className='explore-inner wow fadeInLeft' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                                <div className='sale-btn'><Link to="/">Book Now</Link><img src={extwo} alt="propertyone" /></div>
                                <h5>Marina</h5>
                                <p>Contrary to popular belief, of classical Latin liter from </p>
                            </div>
                           
                    </div>
                    <div className='explore-inner wow fadeInLeft' data-wow-delay="0.2s">
                            <div className='inner-propery'>
                                <div className='sale-btn'><Link to="/">Book Now</Link><img src={exone} alt="propertyone" /></div>
                                <h5>Burj Al Arab</h5>
                                <p>Contrary to popular belief, of classical Latin liter from </p>
                            </div>
                           
                    </div>
            </div>
            </div>
        </div>
  )
}

export default Explore