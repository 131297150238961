import React from 'react'
import './Ourapp.css'
import play from '../../assets/images/play.png'
import appn from '../../assets/images/appn.png'
import frametwo from '../../assets/images/frametwo.png'
import frameone from '../../assets/images/frameone.png'

function Ourapp() {
  return (
        <div className='our-app'>
            <div className='container'>
                <div className='inner-play'>
                <h2>Our mobile app</h2>
                <h3>coming soon.</h3>
                <p>With best-in-class properties and services that maximizes value we are putting  best-in-class properties.</p>
                <div className='playstore wow fadeInUp' data-wow-delay="0.2s">
                    <img src={play} alt="" />
                    <img src={appn} alt="" />
                </div>
               
                <img className='frame-one wow fadeInLeft' src={frameone} alt="" data-wow-delay="0.3s" />
                <img className='frame-two wow fadeInRight' src={frametwo} alt="" data-wow-delay="0.4s" />
                </div>
            </div>
        </div>
  )
}

export default Ourapp