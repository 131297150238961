import React from 'react'
import './Ourcustomer.css'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import useone from '../../assets/images/useone.png'
import usetwo from '../../assets/images/usetwo.png'
import usethree from '../../assets/images/usethree.png'

function Ourcustomer() {
    var settings = {
        dots: false,
        arrow: false,
        loop: true,
        slidesToScroll: 3,
        slidesToShow: 3.1,
        speed: 500,
        infinite: true,
        autoplaySpeed:1500,
        cssEase: 'linear',
    //   autoplay: true
      };

  return (
    <div className='our-customers'>
        <div className='custom-container'>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='our-customer-left'>
                        <h2>What our <br/>customers<br/> say about us.</h2>
                        <p>Contrary to popular belief, of classical Latin content of a page when looking.</p>
                    </div>
                </div>
                <div className='col-md-8'>
                    <div className='our-customer-rigt'>
                    <Slider {...settings}>
                
                <div className='padin'>
                 <div className='inner-propery'>
                               <img src={useone} alt="carone" />
                               <p>“ It is a long established fact that a reader will be distracted by the readable distracted by the readable content of a page when looking at its layout.”</p>
                                <span> -Omar Ali</span>
                           </div>
                </div>
                <div className='padin'>
                 <div className='inner-propery'>
                               <img src={usetwo} alt="carone" />
                               <p>“ It is a long established fact that a reader will be distracted by the readable distracted by the readable content of a page when looking at its layout.”</p>
                                <span> -Omar Ali</span>
                           </div>
                </div>
                <div className='padin'>
                 <div className='inner-propery'>
                               <img src={usethree} alt="carone" />
                               <p>“ It is a long established fact that a reader will be distracted by the readable distracted by the readable content of a page when looking at its layout.”</p>
                                <span> -Omar Ali</span>
                           </div>
                </div>
                <div className='padin'>
                 <div className='inner-propery'>
                               <img src={useone} alt="carone" />
                               <p>“ It is a long established fact that a reader will be distracted by the readable distracted by the readable content of a page when looking at its layout.”</p>
                                <span> -Omar Ali</span>
                           </div>
                </div>
       </Slider>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Ourcustomer