import React, { useState } from 'react'
import location from '../../assets/images/location.png';
import cal from '../../assets/images/cal.png';

function Car() {

    return (
        <div className='seacrh-bn'>
        <div className='seacrhtime'>
                <div className='srchtab'>   
                    <label>Location</label>
                    <span><img src={location} alt="" />1801 Oak Ridge Ln</span>
                </div>
                <div className='srchtab'>
                    <label>Pick Up</label>
                    <span><img src={cal} alt="" />1801 Oak Ridge Ln</span>
                </div>
                <div className='srchtab'>
                    <label>Drop Up</label>
                    <span><img src={cal} alt="" />23 May 2022 · 12:00 pm</span>
                </div>
        </div>
        <div className='srch-btn'>
            <button>Search</button>
        </div>
        </div>
      )
}

export default Car