import React from 'react'
import "./Services.css";
import { Link } from 'react-router-dom';
import serthree from '../../assets/images/serthree.png'
import sertwo from '../../assets/images/sertwo.png'
import serone from '../../assets/images/serone.png'
import lines from '../../assets/images/lines.png'


function Services() {
    const servicesList = [
        {img:serthree, name:'Rental Property', data:'It is a long established fact a reader will be some a random distracted by the readablity content just to add another line to the additional of a page when looking.', link:'Explore More'},
        {img:serone, name:'Car Rental', data:'Distracted by the readable content of a page when looking at and some of the random text its layout.', link:'Explore More'},
        {img:sertwo, name:'Touring', data:'It is a long established fact a reader will be some a random distracted by the readablity content just to add another line to the additional of a page when looking.', link:'Explore More'}

    ]

  return (
   <div className='services'>
    <div className='container'>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='services-left'>
                        <h2>Services<br/> that we offer?</h2>
                        <p>Contrary to popular belief, of classical Latin literature.</p>
                    </div>
                </div>
                <div className='col-md-8'>
                <div className='services-right wow fadeInRight' data-wow-delay="1s">
                    {servicesList.map((inservices) => 
                    
                    <div className='inner-ser'>
                        <img src={inservices.img} alt="" />
                        <h4>{inservices.name}</h4>
                        <p>{inservices.data}</p>
                        <Link to="/" >{inservices.link} <i class="fas fa-arrow-right"></i>  </Link>
                    </div>
                   
                    )}
                     </div>
                   {/* <div className='services-right'>
                    <div className='inner-ser'>
                        <img src={serthree} alt="" />
                        <h4>Rental Property</h4>
                        <p>It is a long established fact a reader will be some a random distracted by the readablity content just to add another line to the additional of a page when looking.</p>
                        <Link to="/">Explore More <i class="far fa-long-arrow-right"></i></Link>
                    </div>
                    </div> */}
                </div>
            </div>
    
    </div>
    </div>
  )
}

export default Services