import React, { useEffect, useState } from 'react'
import axios from "axios";
import Slider from "react-slick";
import propertyone from '../../assets/images/propertyone.png';
import propertytwo from '../../assets/images/propertytwo.png';
import { Link } from 'react-router-dom'
import build from '../../assets/images/build.webp'
import buildt from '../../assets/images/buildt.webp'
import buildr from '../../assets/images/buildr.webp'
import igone from '../../assets/images/igone.webp'
import igtwo from '../../assets/images/igtwo.webp'
import igthree from '../../assets/images/igthree.webp'

function PropertyMobile(props, heading, headingnew) {
    const [userdata, setUser] = useState([]);

    const fetchData = () => {
        return axios.get("https://socket.rentvip.com/api/home")
              .then((response) => setUser(response.data.data.properties.splice(0,6)));
           
      }
      console.log(userdata);
    
      useEffect(() => {
        fetchData();    
      },[])

    var settings = {
        dots: false,
        arrow: false,
        slidesToShow: 1.07,
       
      };
  return (
    
    <div className='mbile-slider'>
      <div className='container'>
      <h4>{props.heading}</h4>
         <Slider {...settings}>
         {userdata && userdata.map((property,i) => 
                          <div className='col-md-4' datawow-delay="0.2s" key={i}>
                          <div className='inner-propery'>
                              <div className='sale-btn'><Link to="/productdetail" state={property}>Book Now</Link><img src={require('../../assets/images/'+property.image[0].name+'.webp')} alt="propertyone" /></div>
                              <h5>{property.name}</h5>
                              <p>{property.location}</p>
                          </div>
                         
                  </div>
                    )}
         </Slider>
    </div>
    </div>
    
  )
}

export default PropertyMobile