import React from 'react'
// import avail from '../../assets/images/avail.png';  
import "./Availnow.css";

function Availnow() {
  return (
        <div className='avail-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='avail-left'>
                            <h4>Free luxury ride from/to the airport.</h4>
                            <p>Contrary to popular belief, of classical Latin content of a page looking at its literature from 45 BC, making.</p>
                            <button className='common-btn'><span>Avail Now</span></button>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='avail-right'>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
   
  )
}

export default Availnow